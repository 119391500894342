<template>
  <div class="user_yijian">
		<el-card>
			<el-row class="left">
				<el-col :span="24">
					<el-form :inline="true"  class="demo-form-inline">
						<el-form-item label="订单编号">
							<el-input v-model="searchText" placeholder="请输入订单编号" clearable></el-input>
						</el-form-item>
						<el-form-item label="技师名称">
							<el-input v-model="jishi_name" placeholder="请输入技师名称" clearable></el-input>
						</el-form-item>
						<el-form-item label="投诉时间">
							<el-date-picker
					      v-model="datePicker"
					      type="daterange"
					      range-separator="至"
					      start-placeholder="开始日期"
					      end-placeholder="结束日期"
					      value-format="yyyy-MM-dd">
					    </el-date-picker>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<!-- 服务列表 -->
			<el-table :data="tousu_list" border style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column type="expand">
		      <template slot-scope="props">
		        <el-form label-position="left" inline class="demo-table-expand">
		          <el-row>
					  		<el-col :span="24">
				          <el-form-item label="客户微信：">
				          	<div class="cell el-tooltip">
											<span class="el-avatar el-avatar--square" style="height: 60px; width: 60px; line-height: 60px;margin-left: 10px;">
												<el-image :src="props.row.avatarUrl" fit="fit" :preview-src-list="[props.row.avatarUrl]"></el-image>
											</span>
											<span>{{ props.row.nickName }}</span>
										</div>
				          </el-form-item>
				       	</el-col>
				       	<el-col :span="24">
				          <el-form-item label="服务项目：">
				            <span>{{ props.row.service_name }}</span>
				          </el-form-item>
				       	</el-col>
				       	<el-col :span="24">
				          <el-form-item label="总计：">
				            <span>¥{{ props.row.pay_fee }}</span>
				          </el-form-item>
				       	</el-col>
				       	<el-col :span="24">
				          <el-form-item label="投诉内容：">
				            <span>{{ props.row.content }}</span>
				          </el-form-item>
				        </el-col>
				       	<el-col :span="24">
				          <el-form-item label="投诉图片：">
				            <div class="cell el-tooltip">
											<span v-for="(item,index) in props.row.img_list" :key="index" class="el-avatar el-avatar--square" style="height: 60px; width: 60px; line-height: 60px;margin-left: 10px;">
												<el-image :src="item" fit="fit" :preview-src-list="props.row.img_list"></el-image>
											</span>
										</div>
				          </el-form-item>
				        </el-col>
				        <el-col :span="24">
				          <el-form-item label="回复内容：">
				            <span>{{ props.row.result_content }}</span>
				          </el-form-item>
				        </el-col>
				        <el-col :span="24">
				          <el-form-item label="回复时间：">
				            <span>{{ props.row.result_time }}</span>
				          </el-form-item>
				        </el-col>
				        <el-col :span="24">
				          <el-form-item label="是否撤销：">
				            <span>{{ props.row.is_cancel }}</span>
				          </el-form-item>
				        </el-col>
				      </el-row>
		        </el-form>
		      </template>
		    </el-table-column>
				<el-table-column prop="tousu_id" label="ID" width="80"></el-table-column>
				<el-table-column prop="order_sn" label="订单编号" width="180"></el-table-column>
				<el-table-column label="技师形象照" width="100">
					<template slot-scope="scope">
						<div>
							<el-avatar shape="square" size="large" :src="scope.row.gzxxz_img"></el-avatar>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="jishi_name" label="技师名称"></el-table-column>
				<el-table-column :show-overflow-tooltip="true" prop="consignee" label="投诉客户"></el-table-column>
				<el-table-column prop="phone" label="联系方式"></el-table-column>
				<el-table-column label="是否回复" width="80">
					<template slot-scope="scope">
						<div>
							<el-tag type="primary" v-if="scope.row.result_status" disable-transitions>已回复</el-tag>
							<el-tag type="warning" v-else disable-transitions>待回复</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="add_time" label="投诉时间" width="165"></el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		data() {
			return {
				total: 0,
				queryInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				searchText: "",
				jishi_name: "",
				datePicker: "",
				tousu_list: [],
			};
		},
		components: {
			Pagination
		},
		created() {
			that = this;
			if (!this.$store.state.userInfo) {
	        // 防止未登录
	        this.$router.push({path: '/login'})
	    }else{
					this.getTousuList();
			}
		},
		methods: {
			getTousuList() {
				let that = this;
				var url = 'order/tousu_list';
				let params = {
					order_sn: this.searchText,
					jishi_name: this.jishi_name,
					date_time: this.datePicker,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.tousu_list = res.list;
						console.log(res.list);
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			search(){
				this.getTousuList();
			},
			// 重置
			reset() {
				this.searchText = "";
				this.jishi_name = "";
				this.datePicker = "";
				this.getTousuList();
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getTousuList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getTousuList();
			},
		},
	};
</script>

<style lang="scss" scoped>
	.user_yijian{
		.left{
		  text-align: left;
		  margin-bottom: 20px;
		}
		.demo-table-expand {
	    font-size: 0;
	  }
	  .demo-table-expand label {
	    width: 90px;
	    color: #99a9bf;
	  }
	  .demo-table-expand .el-form-item {
	    margin-right: 0;
	    margin-bottom: 0;
	    width: 50%;
	  }
	}
</style>